import '@common/constants/env';

import { Target, WorkInProgress } from '@caecms/work-in-progress';
import { CmsApi } from '@common/api/cms';
import { pageHomeCategory } from '@common/application/store/category';
import { currentStore } from '@common/application/store/common';
import { emitCacheCartNumber } from '@common/application/utils/cart-number';
import { isMobile } from '@common/application/utils/common';
import { useCartNumberListener } from '@common/application/utils/event';
import { ServerSide } from '@common/application/utils/server-side';
import { PageInfoContext } from '@common/application/utils/transform';
import { designOnLink } from '@common/application/utils/transform/map/common';
import getWipComponents from '@common/application/utils/wip';
import { Exceptional } from '@common/components/common/exceptional';
import { PopupAd } from '@common/components/common/popup-ad';
// import { MEMBER_STORE_URL } from '@common/constants/common';
import { LANGUAGE_VALUES } from '@common/constants/language';
import { DisplayLocation } from '@common/types/popup-ad';
import { DesignProvider } from '@dch/design-widget-web';
import { useAsyncEffect } from 'ahooks';
// import { useStyle } from '@dch/design-widget-web/common/hooks/use-style';
import { find, get, isEmpty, omit } from 'lodash-es';
import type { GetServerSidePropsContext, NextPage } from 'next';
import { i18n, useTranslation } from 'next-i18next';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { CustomLayout } from '@/components/common/custom-layout';
import { IMG_ICON_COMMON_EMPTY } from '@/resources/images';

const Home: NextPage = (props: any) => {
  const [pageConfig, setPageConfig] = useState<any>();
  const [pageInfo, setPageInfo] = useState<any>();
  const [error, setError] = useState(false);

  const wip = useMemo(() => new WorkInProgress(getWipComponents()), [pageConfig]);
  const { render } = useMemo(() => wip.createRoot(Target.TO_REACT_ELEMENT), [wip]);

  const [countStatistics, setCountStatistics] = useState({});
  const store = useRecoilValue(currentStore);
  const { t } = useTranslation();

  useAsyncEffect(emitCacheCartNumber, []);
  useCartNumberListener(setCountStatistics);

  useEffect(() => {
    (window as any).stage = [
      ...(props._spend || {}),
      { ttfb: (window as any)._ttfb },
      { didMount: performance.now() }
    ];

    CmsApi.getHome()
      .then((res) => {
        setPageConfig(res.config);
        setPageInfo(omit(res, 'config'));
      })
      .catch(() => setError(true));
  }, []);

  const config = useMemo(
    () => ({
      currency: store?.currencyIsoCode ?? '$',
      local: {
        goods: {
          specialOffer: t('design:goods.specialOffer'),
          addCartButtonText: t('design:goods.addCartButtonText'),
          soldOutButtonText: t('design:goods.soldOutButtonText'),
          viewMoreButtonText: t('design:goods.viewMoreButtonText'),
          soldOut: t('design:goods.soldOut'),
          unShelve: t('design:goods.unShelve'),
          preSale: t('design:goods.preSale')
        },
        sale: {
          titleText: t('design:sale.titleText'),
          dayText: t('design:sale.dayText'),
          saleText: t('design:sale.titleText'),
          viewMoreButtonText: t('design:sale.viewMoreButtonText')
        }
      },
      language: (i18n?.language as LANGUAGE_VALUES) || LANGUAGE_VALUES.TC,
      countStatistics,
      designOnLink
    }),
    [countStatistics]
  );
  if (pageConfig && get(pageConfig, 'body')) {
    return (
      <CustomLayout>
        <div className="design-main" id="container-query">
          <PageInfoContext.Provider value={pageInfo}>
            <DesignProvider value={config}>{render(pageConfig.body.tree)}</DesignProvider>
          </PageInfoContext.Provider>
          <PopupAd displayLocation={DisplayLocation.HOME} />
        </div>
      </CustomLayout>
    );
  }
  if (error) {
    return <Exceptional statusCode={500} />;
  }
  return null;
};

function getPrefetchImgs(pageConfig: any, isMobile: boolean) {
  const children = get(pageConfig, 'body.tree.props.children', null);

  if (!pageConfig || !children || children.length === 0) {
    return null;
  }
  const adList = find(children, (item: any) => item.type === 'imageAd');
  if (!adList) {
    return null;
  }
  const { imageAdList } = adList.props;
  if (!imageAdList || imageAdList.length === 0) {
    return null;
  }

  return imageAdList
    .map((item: any) => {
      const path = get(item, `coverImage.${isMobile ? 'mobile' : 'web'}.path`, null);
      if (item.fileType === 'videoPath') {
        return path;
      }
      return path || get(item, `fileValue.${isMobile ? 'mobile' : 'web'}.path`, null);
    })
    .slice(0, 3);
}

export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
  const i18nProps = await ServerSide.getTranslations(ctx, [
    'common',
    'design',
    'product-list',
    'home'
  ]);

  try {
    const start = Date.now();
    const [categoryList, pageData] = await Promise.all([
      await ServerSide.getCategoryList(ctx),
      await CmsApi.getHome({ ctx })
    ]);
    const pageConfig = get(pageData, 'config');

    if (isEmpty(pageConfig)) {
      const i18nStore = get(i18nProps, '_nextI18Next.initialI18nStore');
      const message = get(i18nStore, `${ctx.locale}.home.emptyConfigMsg`);
      return ServerSide.createException(ctx, {
        statusCode: 404,
        message,
        image: IMG_ICON_COMMON_EMPTY.default.src,
        hideButton: true
      });
    }
    const mobile = isMobile(get(ctx, "req.headers['user-agent']", '') as string);
    const prefetch = getPrefetchImgs(pageConfig, mobile);
    const serverSpeed = Date.now() - start;

    return {
      props: {
        ...i18nProps,
        _spend: [
          {
            i18n: i18nProps?._speed
          },
          { category: categoryList?._speed },
          { server: serverSpeed }
        ],
        prefetch,
        recoilState: {
          [pageHomeCategory.key]: categoryList
        },
        pageSEO: {
          title: 'common:metaData.title',
          description: { key: 'common:metaData.description' },
          keywords: 'common:metaData.keywords',
          useT: true
        }
      }
    };
  } catch (err) {
    return ServerSide.createException(ctx, err);
  }
};
export default Home;
